import * as Data from '../../data'
import * as Selectors from '../../data/selectors'

import { PushGamingLauncher } from './push-gaming-launcher'

function mapState(state) {
  return {
    paused: Selectors.isRealityCheck(state),
  }
}

const connector = Data.connect(mapState)

export default connector(PushGamingLauncher)

