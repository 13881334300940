import * as React from "react";
import PropTypes from "prop-types";

import { Iframe } from "../iframe";

export function PushGamingLauncher(props) {
  const iframeRef = React.useRef(null);

  React.useEffect(() => {
    if(iframeRef.current !== null){
    if (props.paused) {
      iframeRef.current.contentWindow.postMessage({ method: "disable" }, "*");
    } else {
      iframeRef.current.contentWindow.postMessage({ method: "enable" }, "*");
    }}
  }, [iframeRef, props.paused]);

  return (
      <Iframe ref={iframeRef} />
  );
}

PushGamingLauncher.propTypes = {
  paused: PropTypes.bool,
};
